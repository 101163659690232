<template>
  <div>
    <b-table
      :fields="fields"
      :items="posts"
      :sort-by.sync="_sortBy"
      :sort-desc.sync="_sortDesc"
      :responsive="true"
      outlined
      hover
      show-empty
      small
      v-bind="$attrs"
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === _sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(condominiumName)="data">
        {{ data.value }}
      </template>
      <template #cell(createdAt)="data">
        <span class="text-capitalize">
          {{ data.value | date("DD MMMM YYYY, HH:mm") }}
        </span>
      </template>
      <template #cell(body)="data">
        <read-more :text="data.value" />
      </template>
      <template #cell(photos)="data">
        <span v-if="data.value && data.value.length">
          <b-icon
            class="clickable"
            icon="camera-fill"
            @click="openGallery(data.value)"
          />
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(photo)="data">
        <span v-if="data.value">
          <b-icon
            class="clickable"
            icon="camera-fill"
            @click="openGallery([data.value])"
          />
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(hasDocuments)="data">
        <b-icon v-if="data.value" icon="paperclip" variant="black"></b-icon>
      </template>
      <template #cell(totalComments)="data">
        {{ data.value }}
      </template>
      <template v-if="$is1Board" #cell(viewsNumber)="data">
        {{ data.value }}
      </template>
      <template v-if="$is1Board" #cell(likesNumber)="data">
        {{ data.value }}
      </template>
      <template #cell(author)="data">
        <b-avatar class="avatar mr-3" :src="data.item.authorAvatar" />
        {{ data.value }}
      </template>
      <template #cell(role)="data">
        {{ $is1Board ? data.value : $t(`userRoles.${data.value}`) }}
      </template>
      <template #cell(group)="data">
        {{ data.value.name ? data.value.name : "-" }}
      </template>
      <template #cell(reported)="data">
        <b-icon
          v-if="data.item.reported"
          variant="danger"
          icon="exclamation-triangle"
        ></b-icon>
      </template>
      <template #cell(actions)="data">
        <b-row class="no-gutters justify-content-end flex-nowrap">
          <b-col
            v-for="(action, index) in actions"
            :key="index"
            class="col-auto"
          >
            <b-button
              size="sm"
              class="ml-1 text-white"
              :variant="action.variant || 'secondary'"
              @click="
                action.callbackFunc(data.item.id, data.item.condominiumId)
              "
            >
              {{ $t(action.label) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <light-box
      v-if="photos.length > 0"
      ref="lightbox"
      :media="photos"
      :show-light-box="false"
    />
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
import { appMixin } from "../../mixins";
import ReadMore from "../Base/BaseReadMore";
export default {
  mixins: [appMixin],
  components: {
    LightBox,
    ReadMore
  },
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      photos: []
    };
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    openGallery(photos) {
      this.photos = photos.map(p => ({ thumb: p.url, src: p.url }));
      this.$refs.lightbox.showImage(0);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-icon {
  width: 20px;
}
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}
</style>
